import React from "react";
import NewsSlider from "./NewsSlider";

function MainPage() {
    return (
        <div className="main-page">
            <div className="main-page-news">

                <h1>Новости</h1>
                <img src="/img/icons8-огонь-48.png"/>
            </div>
            <NewsSlider />
        </div>
    );
}

export default MainPage;
