import React, { useState } from "react";
import './OtherPage.css'; // Подключаем CSS файл

function OtherPage({ goBack }) {
    const [selectedBlock, setSelectedBlock] = useState(null);
    const [isAnimating, setIsAnimating] = useState(false);

    const blocks = [
        { id: 1, title: "BLACKRUSSIA на ПК", content: "Контент блока 1", image: "icons8-огонь-48.png" },
        { id: 2, title: "Блок 2", content: "Контент блока 2", image: "icons8-огонь-48.png" },
        { id: 3, title: "Блок 3", content: "Контент блока 3", image: "icons8-огонь-48.png" }
    ];

    const handleBlockClick = (block) => {
        setIsAnimating(true);
        setTimeout(() => {
            setSelectedBlock(block);
            setIsAnimating(false);
        }, 300); // Длительность анимации
    };

    const handleBackClick = () => {
        setIsAnimating(true);
        setTimeout(() => {
            setSelectedBlock(null);
            setIsAnimating(false);
        }, 300); // Длительность анимации
    };

    return (
        <div className={`other-page-container ${isAnimating ? "fade" : ""}`}>
            {selectedBlock ? (
                <div>
                    <div className={`block-detail ${isAnimating ? "fade" : ""}`}>
                        <div className="block-content">
                            <h2>{selectedBlock.title}</h2>
                            <p>{selectedBlock.content}</p>
                        </div>
                    </div>
                    <div className="block-back">
                        <button onClick={handleBackClick}>Вернуться назад</button>
                    </div>
                </div>

            ) : (
                <div className="block-list">
                    {blocks.map((block) => (
                        <div
                            key={block.id}
                            className="block-item"
                            onClick={() => handleBlockClick(block)}
                        >
                            <img src={`/img/${block.image}`} alt={block.title} />
                            <h2>{block.title}</h2>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default OtherPage;
