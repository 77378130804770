import React from "react";

function BottomNav({ setCurrentPage }) {
    return (
        <div className="bottom-nav">
           <div className="bottom-nav-home">
               <img src="/img/icons8-главная-64.png" onClick={() => setCurrentPage("main")}/>
           </div>
            <div className="bottom-nav-home">
                <img src="/img/icons8-закладка-64.png" onClick={() => setCurrentPage("other")}/>
            </div>
        </div>
    );
}

export default BottomNav;
