import React, { useState } from "react";
import MainPage from "./MainPage";
import OtherPage from "./OtherPage";
import BottomNav from "./BottomNav";
import './App.css'; // Подключаем CSS

function App() {
    const [currentPage, setCurrentPage] = useState("main");
    const [isAnimating, setIsAnimating] = useState(false);

    const handlePageChange = (page) => {
        setIsAnimating(true);
        setTimeout(() => {
            setCurrentPage(page);
            setIsAnimating(false);
        }, 300); // Длительность анимации должна совпадать с длительностью transition
    };

    return (
        <div className={`app`}>
            <div className="header">
                <img src="/img/icons8-профиль-кошки-48.png" />
                <p>Вася Пупкин</p>
            </div>
            <div className={`content ${isAnimating ? "fade" : ""}`}>
                {currentPage === "main" ? <MainPage /> : <OtherPage goBack={() => handlePageChange("main")} />}
            </div>
            <BottomNav setCurrentPage={handlePageChange} />
        </div>
    );
}

export default App;
