import React from "react";

const newsItems = [
    { id: 1, title: "Новость 1", content: "Описание новости 1", image:  '/img/cucumber.png'},
];

function NewsSlider() {
    return (
        <div className="news-slider">
            {newsItems.map((news) => (
                <div key={news.id} className="news-item">
                    <img src={news.image} />
                    <h2>{news.title}</h2>
                    <p>{news.content}</p>
                </div>
            ))}
        </div>
    );
}

export default NewsSlider;
